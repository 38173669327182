import React from "react"
import Layout from "../components/Layout"

import * as ResearchNavBarStyles from "../styles/research-navbar.module.css"
import * as RecentStyles from "../styles/recent.module.css"

import JournalArticlesJSONData from "../../content/research-journal-articles.json"
import PolicyPapersJSONData from "../../content/research-policy-papers.json"
import BookChaptersJSONData from "../../content/research-book-chapters.json"
import EditedCollectionsJSONData from "../../content/research-edited-collections.json"
import BookReviewJSONData from "../../content/research-book-review.json"
import CommentariesJSONData from "../../content/research-commentaries.json"

export default function Research() {
  return (
    <Layout>
      <h1>Research</h1>
      <div>
        <ul className={ResearchNavBarStyles.researchNavbar__list}>
          <li className={ResearchNavBarStyles.researchNavbar__items}>
            <a className="nav-link" id="nav-li" href="#journalArticles">
              Journal Articles
            </a>
          </li>
          <li className={ResearchNavBarStyles.researchNavbar__items}>
            <a className="nav-link" id="nav-li" href="#policyPapers">
              Policy Papers/Briefings
            </a>
          </li>
          <li className={ResearchNavBarStyles.researchNavbar__items}>
            <a className="nav-link" id="nav-li" href="#bookChapters">
              Book chapters
            </a>
          </li>
          <li className={ResearchNavBarStyles.researchNavbar__items}>
            <a className="nav-link" id="nav-li" href="#editedCollections">
              Edited Collections
            </a>
          </li>
          <li className={ResearchNavBarStyles.researchNavbar__items}>
            <a className="nav-link" id="nav-li" href="#bookReview">
              Book review
            </a>
          </li>
          <li className={ResearchNavBarStyles.researchNavbar__items}>
            <a className="nav-link" id="nav-li" href="#commentaries">
              Analysis/Commentaries
            </a>
          </li>
        </ul>
      </div>

      <section id="journalArticles">
        <div className={RecentStyles.content__card}>
          <h3>{JournalArticlesJSONData.category}</h3>
          <ul>
            {JournalArticlesJSONData.works.map((data, index) => {
              return <li key={`content_item_${index}`}>{data.title}</li>
            })}
          </ul>
        </div>
      </section>

      <section id="policyPapers">
        <div className={RecentStyles.content__card}>
          <h3>{PolicyPapersJSONData.category}</h3>
          <ul>
            {PolicyPapersJSONData.works.map((data, index) => {
              return <li key={`content_item_${index}`}>{data.title}</li>
            })}
          </ul>
        </div>
      </section>

      <section id="bookChapters">
        <div className={RecentStyles.content__card}>
          <h3>{BookChaptersJSONData.category}</h3>
          <ul>
            {BookChaptersJSONData.works.map((data, index) => {
              return <li key={`content_item_${index}`}>{data.title}</li>
            })}
          </ul>
        </div>
      </section>

      {/* <section id="editedCollections">
        <div className={RecentStyles.content__card}>
          <h3>{EditedCollectionsJSONData.category}</h3>
          <ul>
            {EditedCollectionsJSONData.works.map((data, index) => {
              return <li key={`content_item_${index}`}>{data.title}</li>
            })}
          </ul>
        </div>
      </section> */}

      <section id="bookReview">
        <div className={RecentStyles.content__card}>
          <h3>{BookReviewJSONData.category}</h3>
          <ul>
            {BookReviewJSONData.works.map((data, index) => {
              return <li key={`content_item_${index}`}>{data.title}</li>
            })}
          </ul>
        </div>
      </section>

      <section id="commentaries">
        <div className={RecentStyles.content__card}>
          <h3>{CommentariesJSONData.category}</h3>
          <ul>
            {CommentariesJSONData.works.map((data, index) => {
              return (
                <li key={`content_item_${index}`}>
                  {data.title}
                  {data.date}
                  {data.etc}
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </Layout>
  )
}
